import { useContext } from "react";
import { MediaQueryContext } from "../providers";

export function useIsMobile() {
  const contextValue = useContext(MediaQueryContext);

  if (contextValue === undefined) {
    throw new Error("useIsMobile should be wrapped into MediaQueryProvider");
  }

  return contextValue;
}
