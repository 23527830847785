import { Box, Input } from "@twilio-paste/core";
import { useTheme } from "@twilio-paste/theme";

import ParticipantsView from "./ParticipantsView";
// import Settings from "../settings/Settings";
import { ConvosDrawerButton } from "./ConvosDrawerButton";
import { useState, useEffect, useRef, FC } from "react";
import { ReduxConversation } from "../../store/reducers/convoReducer";
import { ReduxParticipant } from "../../store/reducers/participantsReducer";
import { useIsMobile } from "../../lib/hooks";
import { useSelector } from "react-redux";
import { AppState } from "../../store";

type TProps = {
  convoSid: string;
  participants: ReduxParticipant[];
  convo: ReduxConversation;
  updateConvoName: (title: string) => void;
};

const ConversationDetails: FC<TProps> = ({
  convo,
  participants,
  updateConvoName,
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  // const [isManageParticipantOpen, setIsManageParticipantOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(convo.friendlyName ?? convo.sid);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const isEmbedded = useSelector((state: AppState) => state.common.isEmbedded);

  // TODO: temporarily removed edit functionality
  // const handleEditClick = () => {
  //   setEditedText(convo.friendlyName ?? convo.sid);
  //   setIsEditing(true);
  // };

  const handleInputChange = (convoName: string) => {
    setEditedText(convoName);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setIsEditing(false);
        if (editedText !== convo.friendlyName) {
          updateConvoName(editedText);
        }
      }
    };

    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        setIsEditing(false);
        if (editedText !== convo.friendlyName) {
          updateConvoName(editedText);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [editedText]);

  if (isEmbedded) {
    return null;
  }

  return (
    <Box
      style={{
        minHeight: 65,
        maxHeight: 65,
        paddingLeft: 16,
        paddingRight: 16,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: theme.borderColors.colorBorderWeak,
      }}
    >
      <Box
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "8px",
          }}
        >
          {isMobile && <ConvosDrawerButton />}
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 8,
            }}
            color="colorText"
            fontFamily="fontFamilyText"
            fontSize="fontSize50"
            lineHeight="lineHeight80"
            fontWeight="fontWeightBold"
            maxHeight="100%"
            // onClick={handleEditClick}
          >
            {isEditing ? (
              <Input
                type="text"
                value={editedText}
                onChange={(e) => handleInputChange(e.target.value)}
                ref={inputRef}
              />
            ) : (
              <>{convo.friendlyName ?? convo.sid}</>
            )}
            {/* <EditIcon decorative={false} title="Edit conversation name" /> */}
          </Box>
        </div>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <ParticipantsView
            participants={participants}
            // onParticipantListOpen={() => setIsManageParticipantOpen(true)}
            onParticipantListOpen={() => null}
          />

          {/* TODO: mep removed */}
          {/* <Settings
            convo={convo}
            participants={participants}
            isManageParticipantOpen={isManageParticipantOpen}
            setIsManageParticipantOpen={setIsManageParticipantOpen}
          /> */}
        </Box>
      </Box>
    </Box>
  );
};

export default ConversationDetails;
