import { FirebaseApp, initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  Messaging,
  onMessage,
} from "firebase/messaging";
import { Client } from "@twilio/conversations";

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

const notificationSound = new Audio("/notification.mp3");

const firebaseConfig = {
  apiKey:
    process.env.REACT_APP_FIREBASE_API_KEY ??
    "AIzaSyAtNJnohsmUYpI90tb6T-UeCUJiY3pq3Tk",
  authDomain:
    process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ??
    "twilio-test-1207a.firebaseapp.com",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID ?? "twilio-test-1207a",
  storageBucket:
    process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ??
    "twilio-test-1207a.appspot.com",
  messagingSenderId:
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ?? "586538317900",
  appId:
    process.env.REACT_APP_FIREBASE_APP_ID ??
    "1:586538317900:web:6fd6675e8fe4e3d6719ce9",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyA_w4RYmEJdBWDMLekNGYXdRLxejHln2hc",
//   authDomain: "pet-proj-df98e.firebaseapp.com",
//   projectId: "pet-proj-df98e",
//   storageBucket: "pet-proj-df98e.appspot.com",
//   messagingSenderId: "972289934038",
//   appId: "1:972289934038:web:5fae5b07ce267a78ca3f77",
//   measurementId: "G-BQGNQSC4JP",
// };

let app: FirebaseApp;
let messaging: Messaging;
let initialized = false;

try {
  app = initializeApp(firebaseConfig);
  messaging = getMessaging(app);
  initialized = true;
} catch {
  console.warn("Couldn't initialize firebase app");
}

export const initFcmServiceWorker = async (
  convoClient: Client
): Promise<void> => {
  if (!initialized || !convoClient) {
    return;
  }

  try {
    await navigator.serviceWorker.register("firebase-messaging-sw.js");

    await navigator.serviceWorker.ready;

    const fcmToken = await getToken(messaging);

    if (!fcmToken) {
      console.log("FcmNotifications: can't get fcm token");
      return;
    }

    await convoClient.setPushRegistrationId("fcm", fcmToken);

    onMessage(messaging, (payload) => {
      notificationSound.play().catch((error) => {
        console.error("Error playing sound:", error);
      });

      convoClient.handlePushNotification(payload);
    });

    console.log("ServiceWorker registered with scope:");
  } catch (e) {
    console.log("ServiceWorker registration failed:", e);
    throw e;
  }
};

export const forceSWUpdate = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      registrations.map((r) => {
        r.unregister();
      });
    });
    window.location.reload();
  }
};
