import { RU_LOCALE } from "../lib/constants";

interface Translations {
  [key: string]: {
    [key: string]: string;
  };
}

interface LanguageOption {
  code: string;
  flag: string;
}

export const languageOptions: {
  languages: LanguageOption[];
} = {
  languages: [
    {
      code: RU_LOCALE,
      flag: "🇷🇺",
    },
    {
      code: "en-US",
      flag: "🇺🇸",
    },
  ],
};

export const translations: Translations = {
  "en-US": {
    greeting: "Select a conversation on the left to get started.",
    online: "Online",
    connecting: "Connecting",
    offline: "Offline",
    createNewConvo: "Create New Conversation",
    newConvo: "New Conversation",
    convoName: "Conversation name",
    convoDescription: "Enter conversation name",
    editConvo: "Edit Conversation",
    convoError: "Add a conversation title to create a conversation.",
    convoSearch: "Search",
    convoEmpty: "No messages",
    convoLoading: "Loading...",
    convoPlaceholder: "Add your message",
    cancel: "Cancel",
    save: "Save",
    back: "Back",
    remove: "Remove",
    signin: "Sign In",
    signout: "Sign Out",
    userProfileTxt: "User Profile",
    identityTxt: "Identity",
    friendlyNameTxt: "Name (friendly_name)",
    selectLocal: "Select a Local",
    clockFormat: "24 hour clock",
    clockHelper: "format for timestamps",
    muteConvo: "Mute Conversation",
    unmuteConvo: "Unmute Conversation",
    manageParticipants: "Manage Participants",
    participants: "Participants",
    addParticipant: "Add Participant",
    smsParticipant: "SMS Participant",
    whatsAppParticipant: "Whatsapp participant",
    chatParticipant: "Chat participant",
    leaveConvo: "Leave Conversation",
    addParticipants: "Add Participants",
    addWhatsAppParticipant: "Add WhatsApp participant",
    addSMSParticipant: "Add SMS participant",
    addChatParticipant: "Add Chat participant",
    otherParticipants: "and {count} other participants",
    singularParticipant: "and 1 other participant",
    whatsAppNum: "WhatsApp number",
    whatsAppHelpTxt: "The WhatsApp phone number of the participant.",
    smsNum: "Phone number",
    smsHelpTxt: "The phone number of the participant.",
    proxyNum: "Proxy phone number",
    proxyNumHelpTxt:
      "The Twilio phone number used by the participant in Conversations.",
    userIdentity: "User identity",
    userIdentityHelperTxt:
      "The identity used by the participant in Conversations.",
    mediaMessage: "Media message",
    username: "Username",
    password: "Password",
    brand: "Brand",
    convoList: "Conversations",
  },
  [RU_LOCALE]: {
    greeting: "Выберите беседу слева, чтобы начать.",
    online: "В сети",
    connecting: "Подключение",
    offline: "Не в сети",
    createNewConvo: "Создать новую беседу",
    newConvo: "Новая беседа",
    convoName: "Название беседы",
    convoDescription: "Введите название беседы",
    editConvo: "Изменить беседу",
    convoError: "Добавьте название беседы, чтобы создать беседу.",
    convoSearch: "Поиск",
    convoEmpty: "Нет сообщений",
    convoLoading: "Загрузка...",
    convoPlaceholder: "Добавьте ваше сообщение",
    cancel: "Отмена",
    save: "Сохранить",
    back: "Назад",
    remove: "Удалить",
    signin: "Войти",
    signout: "Выйти",
    userProfileTxt: "Профиль пользователя",
    identityTxt: "Идентификатор",
    friendlyNameTxt: "Имя (friendly_name)",
    selectLocal: "Выберите локаль",
    clockFormat: "24-часовой формат",
    clockHelper: "формат для временных меток",
    muteConvo: "Отключить звук беседы",
    unmuteConvo: "Включить звук беседы",
    manageParticipants: "Управление участниками",
    participants: "Участники",
    addParticipant: "Добавить участника",
    smsParticipant: "Участник SMS",
    whatsAppParticipant: "Участник WhatsApp",
    chatParticipant: "Участник чата",
    leaveConvo: "Покинуть беседу",
    addParticipants: "Добавить участников",
    addWhatsAppParticipant: "Добавить участника WhatsApp",
    addSMSParticipant: "Добавить участника SMS",
    addChatParticipant: "Добавить участника чата",
    otherParticipants: "и еще {count} участников",
    singularParticipant: "и еще 1 участник",
    whatsAppNum: "Номер WhatsApp",
    whatsAppHelpTxt: "Номер телефона WhatsApp участника.",
    smsNum: "Номер телефона",
    smsHelpTxt: "Номер телефона участника.",
    proxyNum: "Номер телефона прокси",
    proxyNumHelpTxt:
      "Номер телефона Twilio, используемый участником в беседах.",
    userIdentity: "Идентификатор пользователя",
    userIdentityHelperTxt: "Идентификатор, используемый участником в беседах.",
    mediaMessage: "Медиа-сообщение",
    username: "Имя пользователя",
    password: "Пароль",
    brand: "Бренд",
    convoList: "Беседы",
  },
};

export const getTranslation = (language: string, key: string): string => {
  if (translations[language] && translations[language][key]) {
    return translations[language][key];
  } else {
    return `Translation not available for '${key}' in '${language}'`;
  }
};
