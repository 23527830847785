import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import { Theme } from "@twilio-paste/core/theme";
import { Box } from "@twilio-paste/core";

import App from "./components/App";
import styles from "../src/styles";
import { MediaQueryProvider } from "./providers";

ReactDOM.render(
  <Box style={styles.app}>
    <React.StrictMode>
      <MediaQueryProvider>
        <Provider store={store}>
          <Theme.Provider theme="twilio">
            <Box style={styles.innerBox}>
              <App />
            </Box>
          </Theme.Provider>
        </Provider>
      </MediaQueryProvider>
    </React.StrictMode>
  </Box>,
  document.getElementById("root")
);
