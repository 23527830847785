import { ChangeEvent, FC } from "react";
import { Client } from "@twilio/conversations";

import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators, AppState } from "../../store";
import { Label, Option, Select } from "@twilio-paste/core";
import { getTranslation } from "../../utils/localUtils";
import { PRIMARY_COLOR } from "../../branding";
import { getTwilioToken } from "../../crmApi";
import { LSService } from "../../lib/helpers";

type TProps = {
  client?: Client;
};

const SelectBrand: FC<TProps> = ({ client }) => {
  const dispatch = useDispatch();

  const { saveSelectedCrmBrand, login } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const local = useSelector((state: AppState) => state.local);
  const brandTxt = getTranslation(local, "brand");

  const brandId = useSelector(
    (state: AppState) => state.crmBrands.selectedBrand
  );
  const brandOptions = useSelector(
    (state: AppState) => state.crmBrands.brandOptions
  );

  const handleChangeBrand = async (e: ChangeEvent<HTMLSelectElement>) => {
    const brandId =
      brandOptions.find(({ id }) => id === e.currentTarget.value)?.id ||
      brandOptions[0]?.id;

    saveSelectedCrmBrand(brandId);

    const twilioToken = await getTwilioToken(brandId);
    login(twilioToken);

    LSService.setTwilioToken(twilioToken);
    LSService.setDefaultBrand(brandId);

    window.location.reload();
  };

  if (brandId && brandOptions.length === 1) {
    return (
      <p>
        {brandTxt}:{" "}
        <span style={{ color: PRIMARY_COLOR, fontWeight: 600 }}>
          {brandOptions[0].name}
        </span>
      </p>
    );
  }

  return (
    <>
      {Boolean(brandId && brandOptions.length > 0) && (
        <>
          <Label htmlFor="brand">{brandTxt}</Label>

          <div
            style={{
              marginBottom: "16px",
              overflow: "hidden",
              border: "1px solid",
              borderRadius: "4px",
            }}
          >
            <Select
              value={brandId}
              onChange={handleChangeBrand}
              disabled={!client}
            >
              {brandOptions.map(({ id, name }) => (
                <Option key={id} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </div>
        </>
      )}
    </>
  );
};

export default SelectBrand;
