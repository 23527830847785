import { useState, useEffect } from "react";
import { Button } from "@twilio-paste/button";
import { Box } from "@twilio-paste/core";

import { InputType } from "../../types";
import ModalInputField from "../modals/ModalInputField";
import styles from "../../styles";
import useAppAlert from "../../hooks/useAppAlerts";
import React from "react";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store";
import { useDispatch } from "react-redux";
import {
  loginToCrm,
  getBrands,
  getCrmProfile,
  getTwilioToken,
} from "../../crmApi";
import { RU_LOCALE } from "../../lib/constants";
import { LSService } from "../../lib/helpers";
import { LOGO_SUB_TITLE, LOGO_TITLE, SECONDARY_COLOR } from "../../branding";
import { KaztourLogo } from "../common/KaztourLogo";
import { getTranslation } from "../../utils/localUtils";

type TProps = {
  onLogin: (token: string) => void;
};

const getTexts = () => {
  const local = LSService.getLocale() ?? RU_LOCALE;

  const usernameTxt = getTranslation(local, "username");
  const passwordTxt = getTranslation(local, "password");
  const signInText = getTranslation(local, "signin");

  return { usernameTxt, passwordTxt, signInText };
};

const Login: React.FC<TProps> = ({ onLogin }) => {
  const dispatch = useDispatch();

  const [isFormDirty, setFormDirty] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formError, setFormError] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const { usernameTxt, passwordTxt, signInText } = getTexts();

  const [, AlertsView] = useAppAlert();
  const {
    updateCrmUser,
    saveCrmBrands,
    updateCrmProfile,
    saveSelectedCrmBrand,
  } = bindActionCreators(actionCreators, dispatch);

  const handleLogin = async () => {
    const loginResponse = await loginToCrm({ email: username, password });

    LSService.setCRMToken(loginResponse.token);
    updateCrmUser(loginResponse);

    const crmProfile = await getCrmProfile();
    updateCrmProfile(crmProfile);
    LSService.setEmail(crmProfile.email);

    const brands = await getBrands();
    saveCrmBrands(brands);
    saveSelectedCrmBrand(brands[0].id);
    LSService.setDefaultBrand(brands[0].id);

    const token = await getTwilioToken(brands[0].name);
    onLogin(token);
  };

  useEffect(() => {
    const abortController = new AbortController();
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        handleLogin();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return function cleanup() {
      document.removeEventListener("keydown", handleKeyPress);
      abortController.abort();
    };
  }, [password, username]);

  return (
    <Box style={styles.loginContainer}>
      <AlertsView />
      <Box style={styles.loginContent}>
        <div style={{ color: "white" }}>
          <KaztourLogo />
        </div>
        <div style={styles.loginTitle}>{LOGO_TITLE}</div>
        <div style={styles.subTitle}>{LOGO_SUB_TITLE}</div>

        <Box style={styles.loginForm}>
          <Box style={styles.userInput}>
            <ModalInputField
              label={usernameTxt}
              placeholder=""
              isFocused={true}
              error={
                isFormDirty && !username.trim()
                  ? "Enter a username to sign in."
                  : ""
              }
              input={username}
              onChange={(username: string) => {
                setUsername(username);
                setFormError("");
              }}
              onBlur={() => {
                if (password) {
                  setFormDirty(true);
                }
              }}
              id="username"
            />
          </Box>
          <Box style={styles.passwordInput}>
            <ModalInputField
              label={passwordTxt}
              placeholder=""
              error={
                isFormDirty && !password
                  ? "Enter a password to sign in."
                  : formError ?? ""
              }
              input={password}
              onChange={(password: string) => {
                setPassword(password);
                setFormError("");
              }}
              onBlur={() => setFormDirty(true)}
              inputType={showPassword ? InputType.Text : InputType.Password}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
              id="password"
            />
          </Box>
          <Box style={styles.loginButton}>
            <Button
              fullWidth
              disabled={!username || !password}
              variant="primary"
              onClick={handleLogin}
              id="login"
            >
              {signInText}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box style={styles.loginBackground}>
        <Box
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: SECONDARY_COLOR,
            transform: "skewY(-12deg)",
            transformOrigin: "top right",
          }}
        />
      </Box>
    </Box>
  );
};

export default Login;
