import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { ReactElement } from "react";

import Login from "./login/login";
import AppContainer from "./AppContainer";
import { actionCreators, AppState } from "../store";
import { getBrands, getCrmProfile, getTwilioToken } from "../crmApi";
import {
  BRAND_KEY,
  EMBEDDED_APP_KEY,
  TWILIO_TOKEN_KEY,
} from "../lib/constants";
import { Loader } from "./common/Loader";
import { LSService } from "../lib/helpers";

function App(): ReactElement {
  const dispatch = useDispatch();
  const twilioToken = useSelector((state: AppState) => state.token);

  const urlParams = new URLSearchParams(window.location.search);
  const urlToken = urlParams.get(TWILIO_TOKEN_KEY);
  const isEmbedded = urlParams.get(EMBEDDED_APP_KEY);
  const urlBrand = urlParams.get(BRAND_KEY);

  const [loading, setLoading] = useState(true);

  const {
    login,
    saveCrmBrands,
    saveSelectedCrmBrand,
    updateCrmProfile,
    setEmbeddedState,
  } = bindActionCreators(actionCreators, dispatch);

  const handleLogin = (twilioToken: string) => {
    login(twilioToken);
    LSService.setTwilioToken(twilioToken);
  };

  const handleAutoLogin = async () => {
    const brands = await getBrands();
    saveCrmBrands(brands);

    const defaultBrand =
      urlBrand ?? LSService.getDefaultBrand() ?? brands[0].id;

    if (!LSService.getDefaultBrand()) {
      LSService.setDefaultBrand(brands[0].id);
    }

    saveSelectedCrmBrand(defaultBrand);

    const crmProfile = await getCrmProfile();
    updateCrmProfile(crmProfile);
    LSService.setEmail(crmProfile.email);

    const token = await getTwilioToken(defaultBrand);
    return token;
  };

  useEffect(() => {
    setLoading(true);

    if (isEmbedded) {
      setEmbeddedState(true);
    }

    if (urlToken) {
      LSService.setCRMToken(urlToken);
    }

    if (!!LSService.getCrmToken()) {
      handleAutoLogin()
        .then((twilioToken) => handleLogin(twilioToken))
        .catch(() => {
          console.log("Error while getting token");
          LSService.removeAllTokens();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (!twilioToken) {
    return <Login onLogin={handleLogin} />;
  }

  return <AppContainer />;
}

export default App;
