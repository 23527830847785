import { FC, useState } from "react";
import { Client } from "@twilio/conversations";
import { ChevronDoubleLeftIcon } from "@twilio-paste/icons/esm/ChevronDoubleLeftIcon";
import { Box, Input } from "@twilio-paste/core";
import { ChevronDoubleRightIcon } from "@twilio-paste/icons/esm/ChevronDoubleRightIcon";

import ConversationsList from "./ConversationsList";
import styles from "../../styles";

import { useDispatch, useSelector } from "react-redux";
import { filterConversations } from "./../../store/action-creators";
import { AppState } from "../../store";
import { getTranslation } from "./../../utils/localUtils";
import { OPENED_CHAT_KEY } from "../../lib/constants";
import SelectBrand from "./SelectBrand";
import { useIsMobile } from "../../lib/hooks";
import CreateConversationButton from "./CreateConversationButton";

type TProps = {
  client?: Client;
};

const ConversationsContainer: FC<TProps> = ({ client }) => {
  const [listHidden, hideList] = useState(false);
  const dispatch = useDispatch();

  const isMobile = useIsMobile();

  const urlParams = new URLSearchParams(window.location.search);
  const openedSid = urlParams.get(OPENED_CHAT_KEY);

  const local = useSelector((state: AppState) => state.local);
  const search = getTranslation(local, "convoSearch");

  const handleSearch = (searchString: string) => {
    dispatch(filterConversations(searchString));
  };

  if (openedSid) {
    return null;
  }

  return (
    <Box
      style={
        listHidden
          ? { ...styles.convosWrapper(isMobile), ...styles.collapsedList }
          : styles.convosWrapper(isMobile)
      }
    >
      <CreateConversationButton collapsed={false} client={client} />

      {!listHidden && (
        <>
          <Box style={styles.newConvoButton}>
            <SelectBrand client={client} />
            <Box marginTop="space60">
              <Input
                aria-describedby="convo_string_search"
                id="convoString"
                name="convoString"
                type="text"
                placeholder={search}
                onChange={(e) => handleSearch(e.target.value)}
                required
                autoFocus
              />
            </Box>
          </Box>
          <Box style={styles.convoList}>
            <ConversationsList />
          </Box>
        </>
      )}
      {!isMobile && (
        <Box style={styles.collapseButtonBox}>
          <Box
            paddingTop="space30"
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              cursor: "pointer",
            }}
            onClick={() => hideList(!listHidden)}
          >
            {listHidden ? (
              <ChevronDoubleRightIcon decorative={false} title="Collapse" />
            ) : (
              <ChevronDoubleLeftIcon decorative={false} title="Collapse" />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ConversationsContainer;
