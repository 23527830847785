import { ActionType } from "../action-types";
import { Action } from "../actions";

export type TCommonReducer = {
  isEmbedded: boolean;
  isConvoDrawerOpen: boolean;
};

const initialState = {
  isEmbedded: false,
  isConvoDrawerOpen: false,
};

const reducer = (
  state: TCommonReducer = initialState,
  action: Action
): TCommonReducer => {
  switch (action.type) {
    case ActionType.SET_EMBEDDED_STATE:
      return {
        ...state,
        isEmbedded: action.payload,
      };
    case ActionType.SET_CONVO_DRAWER_STATE:
      return {
        ...state,
        isConvoDrawerOpen: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
