import { ActionType } from "../action-types";
import { Action } from "../actions";

export type TBrand = {
  id: string;
  name: string;
};

export type TCrmBrands = TBrand[];

export type TReduxBrands = {
  brandOptions: TCrmBrands;
  selectedBrand: string;
};

const initialState: TReduxBrands = {
  brandOptions: [],
  selectedBrand: "error",
};

const reducer = (
  state: TReduxBrands = initialState,
  action: Action
): TReduxBrands => {
  switch (action.type) {
    case ActionType.SAVE_CRM_BRANDS:
      const brands = action.payload;
      return {
        ...state,
        brandOptions: brands,
      };

    case ActionType.SAVE_SELECTED_BRAND:
      const brand = action.payload;
      return {
        ...state,
        selectedBrand: brand,
      };

    default:
      return state;
  }
};

export default reducer;
