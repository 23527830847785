import TimeAgo from "javascript-time-ago";
import ru from "javascript-time-ago/locale/ru";
import { ReduxMessage } from "./../store/reducers/messageListReducer";
import * as _ from "lodash";
import { RU_LOCALE } from "../lib/constants";

type TFormatMessageTime = {
  dateString: Date;
  lastMessage: boolean;
};

TimeAgo.addDefaultLocale(ru);
const timeAgo = new TimeAgo(RU_LOCALE);

function formatMessageTime({ dateString, lastMessage }: TFormatMessageTime) {
  const date = new Date(dateString);
  const currentDate = new Date();

  if (lastMessage && currentDate.toDateString() !== date.toDateString()) {
    // If it's not the same day, show date.
    return date.toLocaleDateString(RU_LOCALE);
  } else if (currentDate.getTime() - date.getTime() >= 3 * 60 * 60 * 1000) {
    return date.toLocaleTimeString(RU_LOCALE, {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  } else {
    // Otherwise, use the TimeAgo library to format the relative time
    return timeAgo.format(date);
  }
}

export function getMessageTime(message: ReduxMessage) {
  const dateCreated = message.dateCreated;

  if (!dateCreated) {
    return "";
  }

  return formatMessageTime({
    dateString: dateCreated,
    lastMessage: false,
  });
}

export function getLastMessageTime(messages: ReduxMessage[]) {
  const lastMessageDate = _.last(messages)?.dateCreated;

  if (!lastMessageDate) {
    return "";
  }

  return formatMessageTime({
    dateString: lastMessageDate,
    lastMessage: true,
  });
}

export function getFirstMessagePerDate(messages: ReduxMessage[]) {
  // Group messages by dateCreated
  const messagesByDate: Record<string, typeof messages> = {};

  for (const message of messages) {
    if (message.dateCreated) {
      const dateKey = message.dateCreated.toDateString();
      if (!messagesByDate[dateKey]) {
        messagesByDate[dateKey] = [message];
      } else {
        const existingMessage = messagesByDate[dateKey][0];
        if (
          existingMessage &&
          existingMessage.dateCreated &&
          message.dateCreated < existingMessage.dateCreated
        ) {
          messagesByDate[dateKey] = [message];
        }
      }
    }
  }

  // Extract sid values from the earliest messages per day
  const earliestSidsPerDay: string[] = [];
  for (const dateKey in messagesByDate) {
    earliestSidsPerDay.push(messagesByDate[dateKey][0].sid);
  }

  return earliestSidsPerDay;
}
