import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators, AppState } from "../../store";
import { Client } from "@twilio/conversations";
import ConversationsContainer from "./ConversationsContainer";
import { Button } from "@twilio-paste/core";
import { CloseIcon } from "@twilio-paste/icons/esm/CloseIcon";
import { getTranslation } from "../../utils/localUtils";

type TProps = {
  client?: Client;
};

export const ConvosDrawer: FC<TProps> = ({ client }) => {
  const dispatch = useDispatch();

  const isOpen = useSelector(
    (state: AppState) => state.common.isConvoDrawerOpen
  );
  const local = useSelector((state: AppState) => state.local);

  const { setConvoDrawerState } = bindActionCreators(actionCreators, dispatch);
  const convoList = getTranslation(local, "convoList");

  return (
    <div
      style={{
        display: "block",
        position: "absolute",
        top: 0,
        left: isOpen ? 0 : -500,
        opacity: isOpen ? 1 : 0,
        bottom: 0,
        zIndex: 9999,
        backgroundColor: "#F4F4F6",
        padding: "16px",
        transition: "0.2s",
        width: "100vw",
      }}
    >
      <div style={{ width: "100%", height: "100%" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #E5E5E5",
          }}
        >
          <h1
            style={{
              fontSize: "24px",
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            {convoList}
          </h1>
          <Button variant="link" onClick={() => setConvoDrawerState(false)}>
            <CloseIcon decorative={true} title="Open Menu" size="sizeIcon30" />
          </Button>
        </div>
        <div style={{ overflowY: "auto", maxHeight: "90vh", height: "100%" }}>
          <ConversationsContainer client={client} />
        </div>
      </div>
    </div>
  );
};
