import { createContext, useLayoutEffect, useState } from "react";

import type { ReactNode } from "react";

enum Breakpoint {
  Desktop = "(min-width: 768px)",
  Mobile = "(max-width: 767px)",
}

export const MediaQueryContext = createContext<boolean | undefined>(undefined);

interface MediaQueryProviderProps {
  children: ReactNode;
  initialValue?: boolean;
}

export const MediaQueryProvider = ({
  children,
  initialValue = false,
}: MediaQueryProviderProps) => {
  const [isMobile, setIsMobile] = useState(initialValue);

  useLayoutEffect(() => {
    const handleChange = (event: MediaQueryListEvent) => {
      setIsMobile(event.matches);
    };
    const mql = window.matchMedia(Breakpoint.Mobile);

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (mql.addEventListener) {
      mql.addEventListener("change", handleChange);
    } else {
      mql.addListener(handleChange);
    }

    setIsMobile(mql.matches);

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (mql.removeEventListener) {
        mql.removeEventListener("change", handleChange);
      } else {
        mql.removeListener(handleChange);
      }
    };
  }, []);

  return (
    <MediaQueryContext.Provider value={isMobile}>
      {children}
    </MediaQueryContext.Provider>
  );
};
