import { FC, useEffect, useMemo, useRef, useState } from "react";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";

import {
  Client,
  ConnectionState,
  Conversation,
  Message,
  Participant,
} from "@twilio/conversations";
import { Box } from "@twilio-paste/core";

import { actionCreators, AppState } from "../store";
import ConversationContainer from "./conversations/ConversationContainer";
import {
  AddMessagesType,
  SetParticipantsType,
  SetUnreadMessagesType,
} from "../types";
import useAppAlert from "../hooks/useAppAlerts";
import Notifications from "./Notifications";
import stylesheet from "../styles";
import { handlePromiseRejection, successNotification } from "../helpers";
import AppHeader from "./AppHeader";

import { getTwilioToken } from "../crmApi";
import { DEFAULT_BRAND, OPENED_CHAT_KEY, RU_LOCALE } from "../lib/constants";
import { LSService } from "../lib/helpers";
import { useIsMobile } from "../lib/hooks";
import { ConvosDrawer } from "./conversations/ConvosDrawer";
import * as registerSW from "../serviceWorkerRegistration";
import ConversationsContainer from "./conversations/ConversationsContainer";

async function loadUnreadMessagesCount(
  convo: Conversation,
  updateUnreadMessages: SetUnreadMessagesType
) {
  let count = 0;

  try {
    count =
      (await convo.getUnreadMessagesCount()) ??
      (await convo.getMessagesCount());
  } catch (e) {
    console.error("getUnreadMessagesCount threw an error", e);
  }

  updateUnreadMessages(convo.sid, count);
}

async function handleParticipantsUpdate(
  participant: Participant,
  updateParticipants: SetParticipantsType
) {
  const result = await participant.conversation.getParticipants();
  updateParticipants(result, participant.conversation.sid);
}

const AppContainer: FC = () => {
  const dispatch = useDispatch();
  /* eslint-disable */

  const isMobile = useIsMobile();
  const [connectionState, setConnectionState] = useState<ConnectionState>();
  const [client, setClient] = useState<Client>();
  const [clientIteration, setClientIteration] = useState(0);

  const urlParams = new URLSearchParams(window.location.search);
  const openedSid = urlParams.get(OPENED_CHAT_KEY);

  const twilioToken = useSelector((state: AppState) => state.token);
  const currentBrand = useSelector(
    (state: AppState) => state.crmBrands.selectedBrand
  );

  const isEmbedded = useSelector((state: AppState) => state.common.isEmbedded);
  const conversations = useSelector((state: AppState) => state.convos);
  const sid = useSelector((state: AppState) => state.sid);
  const userEmail = useSelector((state: AppState) => state.crmProfile.email);

  const seletedSid = openedSid || sid;

  const sidRef = useRef("");
  const [alertsExist, AlertsView] = useAppAlert();
  sidRef.current = sid;

  const {
    upsertMessages,
    updateLoadingState,
    updateParticipants,
    updateUser,
    updateUnreadMessages,
    startTyping,
    endTyping,
    upsertConversation,
    login,
    removeMessages,
    removeConversation,
    updateCurrentConversation,
    addNotifications,
    logout,
    clearAttachments,
    updateTimeFormat,
    updateLocal,
  } = bindActionCreators(actionCreators, dispatch);

  const updateTypingIndicator = (
    participant: Participant,
    sid: string,
    callback: (sid: string, user: string) => void
  ) => {
    const {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      attributes: { friendlyName },
      identity,
    } = participant;
    if (identity === userEmail) {
      return;
    }
    callback(sid, identity || friendlyName || "");
  };

  async function upsertMessage(
    message: Message,
    upsertMessages: AddMessagesType,
    updateUnreadMessages: SetUnreadMessagesType
  ) {
    //transform the message and add it to redux
    await handlePromiseRejection(async () => {
      if (sidRef.current === message.conversation.sid) {
        await message.conversation.advanceLastReadMessageIndex(message.index);
      }
      upsertMessages(message.conversation.sid, [message]);
      await loadUnreadMessagesCount(message.conversation, updateUnreadMessages);
    }, addNotifications);
  }

  const openedConversation = useMemo(
    () => conversations.find((convo) => convo.sid === seletedSid),
    [sid, conversations]
  );

  useEffect(() => {
    const client = new Client(twilioToken);
    setClient(client);

    client.on("conversationJoined", (conversation) => {
      upsertConversation(conversation);

      conversation.on("typingStarted", (participant) => {
        handlePromiseRejection(
          () =>
            updateTypingIndicator(participant, conversation.sid, startTyping),
          addNotifications
        );
      });

      conversation.on("typingEnded", async (participant) => {
        await handlePromiseRejection(
          async () =>
            updateTypingIndicator(participant, conversation.sid, endTyping),
          addNotifications
        );
      });

      handlePromiseRejection(async () => {
        if (conversation.status === "joined") {
          const result = await conversation.getParticipants();
          updateParticipants(result, conversation.sid);

          const messages = await conversation.getMessages();
          upsertMessages(conversation.sid, messages.items);
          await loadUnreadMessagesCount(conversation, updateUnreadMessages);
        }
      }, addNotifications);
    });
    client.on("conversationRemoved", async (conversation: Conversation) => {
      updateCurrentConversation("");
      await handlePromiseRejection(async () => {
        removeConversation(conversation.sid);
        updateParticipants([], conversation.sid);
      }, addNotifications);
    });
    client.on("messageAdded", async (message: Message) => {
      await upsertMessage(message, upsertMessages, updateUnreadMessages);
      if (message.author === userEmail) {
        clearAttachments(message.conversation.sid, "-1");
      }
    });
    client.on("userUpdated", async (event) => {
      await updateUser(event.user);
    });
    client.on("participantLeft", async (participant) => {
      await handlePromiseRejection(
        async () => handleParticipantsUpdate(participant, updateParticipants),
        addNotifications
      );
    });
    client.on("participantUpdated", async (event) => {
      await handlePromiseRejection(
        async () =>
          handleParticipantsUpdate(event.participant, updateParticipants),
        addNotifications
      );
    });
    client.on("participantJoined", async (participant) => {
      await handlePromiseRejection(
        async () => handleParticipantsUpdate(participant, updateParticipants),
        addNotifications
      );
    });
    client.on("conversationUpdated", async ({ conversation }) => {
      await handlePromiseRejection(
        () => upsertConversation(conversation),
        addNotifications
      );
    });
    client.on("messageUpdated", async ({ message }) => {
      await handlePromiseRejection(
        async () =>
          upsertMessage(message, upsertMessages, updateUnreadMessages),
        addNotifications
      );
    });
    client.on("messageRemoved", async (message) => {
      await handlePromiseRejection(
        () => removeMessages(message.conversation.sid, [message]),
        addNotifications
      );
    });
    client.on("pushNotification", (event) => {
      successNotification({
        message: event.body ?? "",
        addNotifications,
      });
    });
    client.on("tokenAboutToExpire", async () => {
      if (userEmail) {
        const token = await getTwilioToken(currentBrand ?? DEFAULT_BRAND);
        await client.updateToken(token);
        login(token);
      }
    });
    client.on("tokenExpired", async () => {
      if (userEmail) {
        const token = await getTwilioToken(currentBrand ?? DEFAULT_BRAND);
        login(token);
        setClientIteration((x) => x + 1);
      }
    });
    client.on("connectionStateChanged", (state) => {
      setConnectionState(state);
    });

    updateLoadingState(false);

    return () => {
      client?.removeAllListeners();
    };
  }, [clientIteration, twilioToken]);

  useEffect(() => {
    const abortController = new AbortController();
    const use24hTimeFormat = localStorage.getItem("use24hTimeFormat");
    if (use24hTimeFormat !== null) {
      updateTimeFormat(true);
    }
    const locale = LSService.getLocale() || RU_LOCALE;
    updateLocal(locale);

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <>
      <Box style={stylesheet.appWrapper}>
        <AlertsView />
        <Notifications />
        <Box>
          <AppHeader
            user={userEmail ?? ""}
            client={client}
            onForceUpdate={registerSW.forceSWUpdate}
            onSignOut={async () => {
              logout();

              // unregister service workers
              const registrations =
                await navigator.serviceWorker.getRegistrations();
              for (let registration of registrations) {
                registration.unregister();
              }
              LSService.removeAllTokens();
            }}
            connectionState={connectionState ?? "disconnected"}
          />
        </Box>
        <Box style={stylesheet.appContainer(alertsExist, isEmbedded)}>
          {!isMobile && <ConversationsContainer client={client} />}
          <Box style={stylesheet.messagesWrapper}>
            <ConversationContainer
              conversation={openedConversation}
              client={client}
            />
          </Box>
        </Box>
      </Box>
      {isMobile && <ConvosDrawer client={client} />};
    </>
  );
};

export default AppContainer;
