import { Box, Spinner } from "@twilio-paste/core";
import { FC } from "react";

export const Loader: FC = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="absolute"
      height="100%"
      width="100%"
    >
      <Spinner size="sizeIcon110" decorative={false} title="Loading" />
    </Box>
  );
};
