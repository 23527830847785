import { ActionType } from "../action-types";
import { Action } from "../actions";

export type TCrmProfile = {
  id: string;
  first_name: string;
  last_name: string;
  second_name: string | null;
  email: string;
  country: {
    id: string;
    name: string;
  };
  location: {
    id: string;
    name: string;
  };
  organization: {
    id: string;
    name: string;
    code: string;
  };
  department: {
    id: string;
    name: string;
    code: string;
  };
  offices: [
    {
      id: string;
      name: string;
      location: {
        id: string;
        name: string;
      };
    }
  ];
  role: [
    {
      id: string;
      name: string;
      title: string;
    }
  ];
  position: {
    id: string;
    name: string;
    code: string;
  };
  avatar: null;
  active: true;
  phones: [
    {
      phone_type_id: string;
      phone: string;
    }
  ];
  active_brands: string[];
  voip_number: string;
};

export type TReduxProfile = {
  departmentCode: string;
  email: string;
  first_name: string;
  last_name: string;
  organization: string;
  roles: {
    id: string;
    name: string;
    title: string;
  }[];
};

const initialState: TReduxProfile = {
  departmentCode: "",
  email: "",
  first_name: "",
  last_name: "",
  organization: "",
  roles: [],
};

const reduxifyUser = (profile: TCrmProfile): TReduxProfile => ({
  departmentCode: profile.department.code,
  email: profile.email,
  first_name: profile.first_name,
  last_name: profile.last_name,
  organization: profile.organization.name,
  roles: profile.role,
});

const reducer = (
  state: TReduxProfile = initialState,
  action: Action
): TReduxProfile => {
  switch (action.type) {
    case ActionType.UPDATE_CRM_PROFILE:
      const user = action.payload;
      return reduxifyUser(user);
    default:
      return state;
  }
};

export default reducer;
