import { ActionType } from "../action-types";
import { Action } from "../actions";

export type TUser = {
  id: string;
  email: string;
  avatar: null;
  permissions: string[];
  token: string;
  first_name: string;
  last_name: string;
  second_name: null | string;
};

export type TReduxUser = Pick<
  TUser,
  "id" | "email" | "avatar" | "first_name" | "last_name"
>;

const initialState: TReduxUser = {
  id: "",
  email: "",
  avatar: null,
  first_name: "",
  last_name: "",
};

const reduxifyUser = ({
  id,
  email,
  avatar,
  first_name,
  last_name,
}: TUser): TReduxUser => ({
  id,
  email,
  avatar,
  first_name,
  last_name,
});

const reducer = (
  state: TReduxUser = initialState,
  action: Action
): TReduxUser => {
  switch (action.type) {
    case ActionType.UPDATE_CRM_USER:
      const user = action.payload;
      return reduxifyUser(user);
    default:
      return state;
  }
};

export default reducer;
