import { Avatar } from "./Avatar";
import { Text } from "@twilio-paste/core";
import { Menu, MenuButton, MenuItem, useMenuState } from "@twilio-paste/menu";
import { ChevronDownIcon } from "@twilio-paste/icons/esm/ChevronDownIcon";
import { FC, useEffect, useMemo, useState } from "react";
import styles from "../styles";
import { Client, ConnectionState, User } from "@twilio/conversations";
import UserProfileModal from "./modals/UserProfileModal";
import { readUserProfile } from "../api";
import { LOGO_SUB_TITLE, LOGO_TITLE } from "../branding";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators, AppState } from "../store";
import { getTranslation } from "./../utils/localUtils";
import { KaztourLogo } from "./common/KaztourLogo";
import * as registerSW from "../serviceWorkerRegistration";
import {
  neutralNotification,
  successNotification,
  unexpectedErrorNotification,
} from "../helpers";
import { Action, bindActionCreators, Dispatch } from "redux";
import { NotificationsType } from "../store/reducers/notificationsReducer";

type TProps = {
  user: string;
  onSignOut: () => void;
  connectionState: ConnectionState;
  onForceUpdate: () => void;
  client?: Client;
};

const MAX_RETRY_ATTEMPTS = 3;
const RETRY_DELAY = 2000;

const getConnectionState = (
  connectionState: ConnectionState
): "online" | "connecting" | "offline" => {
  switch (connectionState) {
    case "connected":
      return "online";
    case "connecting":
      return "connecting";
    default:
      return "offline";
  }
};

const AppHeader: FC<TProps> = ({
  user,
  onSignOut,
  onForceUpdate,
  connectionState,
  client,
}) => {
  const menu = useMenuState();

  const [showUserProfileModal, setUserProfileModal] = useState(false);

  const userName = useSelector((state: AppState) => state.crmProfile);
  const isEmbedded = useSelector((state: AppState) => state.common.isEmbedded);

  const dispatch = useDispatch();
  const { addNotifications } = bindActionCreators(actionCreators, dispatch);

  const [userProfile, setUserProfile] = useState<User | undefined>(undefined);

  const handleUserProfileModalClose = () => setUserProfileModal(false);

  const local = useSelector((state: AppState) => state.local);

  const online = getTranslation(local, "online");
  const connecting = getTranslation(local, "connecting");
  const offline = getTranslation(local, "offline");
  const signout = getTranslation(local, "signout");
  const userProfileTxt = getTranslation(local, "userProfileTxt");

  const label = useMemo(
    () => getConnectionState(connectionState),
    [connectionState]
  );

  const handleUserProfileModalOpen = async () => {
    const userProfileTemp = await readUserProfile(user, client);
    setUserProfile(userProfileTemp);
    setUserProfileModal(true);
  };

  const attemptServiceWorkerInitialization = async (
    client: Client,
    addNotifications: (
      notifications: NotificationsType
    ) => (dispatch: Dispatch<Action>) => void,
    attemptNumber = 1
  ): Promise<void> => {
    try {
      neutralNotification({
        message: "Пытаемся подключить уведомления...",
        addNotifications,
      });
      await registerSW.initFcmServiceWorker(client);
      successNotification({
        message: "Уведомления разрешены",
        addNotifications,
      });
    } catch (e) {
      console.log(
        `ServiceWorker registration attempt ${attemptNumber} failed:`,
        e
      );

      if (attemptNumber < MAX_RETRY_ATTEMPTS) {
        console.log(`Retrying in ${RETRY_DELAY}ms...`);
        await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
        return attemptServiceWorkerInitialization(
          client,
          addNotifications,
          attemptNumber + 1
        );
      } else {
        unexpectedErrorNotification(
          `${e.toString()}\nНе удалось инициализировать после ${MAX_RETRY_ATTEMPTS} попыток`,
          addNotifications
        );
      }
    }
  };

  useEffect(() => {
    const initializeServiceWorkerIfNeeded = async () => {
      const permission = Notification.permission;
      if (permission !== "granted") {
        console.log("FcmNotifications: can't request permission:", permission);
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await attemptServiceWorkerInitialization(client!, addNotifications);
    };

    initializeServiceWorkerIfNeeded();
  }, [client]);

  const requestPermissionAndSendPush = async (client?: Client) => {
    successNotification({
      message: "Пытаемся подключить уведомления...",
      addNotifications,
    });
    const permission = await Notification.requestPermission();
    if (permission !== "granted") {
      console.log("FcmNotifications: can't request permission:", permission);
      return;
    }

    if (!client) {
      console.log("FcmNotifications: client is not available");
      return;
    }

    await attemptServiceWorkerInitialization(client, addNotifications);
  };

  if (isEmbedded) {
    return null;
  }

  return (
    <div style={styles.appHeader}>
      <div style={{ display: "flex", alignItems: "center", color: "white" }}>
        <div
          style={{
            color: "white",
            display: "flex",
            alignItems: "center",
          }}
        >
          <KaztourLogo />
        </div>
        <div style={{ marginLeft: "12px" }}>
          <p
            style={{
              margin: "0 0 4px 0",
              fontSize: "18px",
              lineHeight: "18px",
            }}
          >
            {LOGO_TITLE}
          </p>
          <p
            style={{
              margin: 0,
              fontSize: "12px",
              lineHeight: "12px",
              color: "#E6ECF2",
            }}
          >
            {LOGO_SUB_TITLE}
          </p>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <div style={styles.userTile}>
          <Avatar
            name={`${userName?.first_name ?? "K"} ${
              userName?.last_name ?? "T"
            }`}
          />
          <div
            style={{
              padding: "0 10px",
            }}
          >
            <Text as="span" style={styles.userName}>
              {`${userName?.first_name ?? "KazTour"} ${
                userName?.last_name ?? "Manager"
              }`}
            </Text>
            <Text
              as="span"
              color={
                label === "online"
                  ? "colorTextPrimaryWeak"
                  : label === "connecting"
                  ? "colorTextIconBusy"
                  : "colorTextWeaker"
              }
              style={styles.userStatus}
            >
              {label === "online"
                ? online
                : label === "connecting"
                ? `${connecting}...`
                : offline}
            </Text>
          </div>

          <MenuButton {...menu} variant="link" size="reset">
            <ChevronDownIcon
              color="colorTextInverse"
              decorative={false}
              title="Settings"
            />
          </MenuButton>
          <Menu {...menu} aria-label="Preferences">
            <MenuItem {...menu} onClick={handleUserProfileModalOpen}>
              {userProfileTxt}
            </MenuItem>
            <MenuItem {...menu} onClick={onSignOut}>
              {signout}
            </MenuItem>
            <MenuItem {...menu} onClick={onForceUpdate}>
              Обновить версию
            </MenuItem>
            <MenuItem
              {...menu}
              onClick={() => requestPermissionAndSendPush(client)}
            >
              Разрешить уведомления
            </MenuItem>
          </Menu>
        </div>
      </div>

      {showUserProfileModal && (
        <UserProfileModal
          isModalOpen={showUserProfileModal}
          handleClose={handleUserProfileModalClose}
          user={userProfile}
        />
      )}
    </div>
  );
};

export default AppHeader;
