import axios from "axios";
import { TUser } from "./store/reducers/crmUserReducer";
import { TCrmProfile } from "./store/reducers/crmProfileReducer";
import { TCrmBrands } from "./store/reducers/crmBrandOptions";
import { LSService } from "./lib/helpers";

type TLoginPayload = {
  email: string;
  password: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const errorHander = (error: any, requestAddress: string) => {
  if (axios.isAxiosError(error) && error.response?.status === 401) {
    throw new Error(error.response.data ?? "Authentication error.");
  }

  console.error(`ERROR received from ${requestAddress}: ${error}\n`);
  throw new Error(`ERROR received from ${requestAddress}: ${error}\n`);
};

export async function loginToCrm({
  email,
  password,
}: TLoginPayload): Promise<TUser> {
  const requestAddress = process.env.REACT_APP_CRM_API_URL as string;

  if (!requestAddress) {
    throw new Error("CRM_API_URL is not configured, cannot login");
  }

  try {
    const response = await axios.post<TLoginPayload, { data: { data: TUser } }>(
      `${requestAddress}/v1/auth`,
      {
        email,
        password,
      }
    );

    return response.data.data;
  } catch (error) {
    return errorHander(error, requestAddress);
  }
}

export async function getTwilioToken(brand: string): Promise<string> {
  const requestAddress = process.env.REACT_APP_CRM_API_URL as string;
  const crmToken = LSService.getCrmToken();

  if (!requestAddress) {
    throw new Error(
      "REACT_APP_ACCESS_TOKEN_SERVICE_URL is not configured, cannot get TWILIO token"
    );
  }

  try {
    const response = await axios.post(
      `${requestAddress}/v1/twilio/token`,
      { brand: brand.toLowerCase() },
      {
        headers: {
          Authorization: crmToken,
        },
      }
    );

    return response.data.data.token;
  } catch (error) {
    return errorHander(error, requestAddress);
  }
}

// export async function getTwilioToken(brand: string): Promise<string> {
//   const requestAddress = "https://test-1786.twil.io/token-service";
//   const crmToken = LSService.getCrmToken();

//   if (!requestAddress) {
//     throw new Error(
//       "REACT_APP_ACCESS_TOKEN_SERVICE_URL is not configured, cannot get TWILIO token"
//     );
//   }

//   try {
//     const response = await axios.post(
//       requestAddress,
//       {
//         identity: "user00",
//         password: "password00",
//       }
//       // {
//       //   headers: {
//       //     Authorization: crmToken,
//       //   },
//       // }
//     );

//     return response.data.token;
//   } catch (error) {
//     return errorHander(error, requestAddress);
//   }
// }

export async function getBrands(): Promise<TCrmBrands> {
  const requestAddress = process.env.REACT_APP_CRM_API_URL as string;
  const crmToken = LSService.getCrmToken();

  if (!requestAddress) {
    throw new Error("CRM_API_URL is not configured, cannot get brands");
  }

  if (!crmToken) {
    throw new Error("token is not configured, cannot process request");
  }

  try {
    const response = await axios.get<
      void,
      {
        headers: { Authorization: string };
        data: { data: TCrmBrands };
      }
    >(`${requestAddress}/v1/brands/twilio`, {
      headers: {
        Authorization: crmToken,
      },
    });

    return response.data.data;
  } catch (error) {
    return errorHander(error, requestAddress);
  }
}

export async function getCrmProfile(): Promise<TCrmProfile> {
  const requestAddress = process.env.REACT_APP_CRM_API_URL as string;
  const token = LSService.getCrmToken();

  if (!requestAddress) {
    throw new Error("CRM_API_URL is not configured, cannot get profile data");
  }

  if (!token) {
    throw new Error("token is not configured, cannot process request");
  }

  try {
    const response = await axios.get<
      void,
      {
        headers: { Authorization: string };
        data: { data: TCrmProfile };
      }
    >(`${requestAddress}/v1/profile`, {
      headers: {
        Authorization: token,
      },
    });

    return response.data.data;
  } catch (error) {
    return errorHander(error, requestAddress);
  }
}
