export const CRM_TOKEN_KEY = "crm_token";
export const TWILIO_TOKEN_KEY = "token";
export const EMBEDDED_APP_KEY = "embedded";
export const EMAIL_KEY = "email";
export const OPENED_CHAT_KEY = "opened_chat";
export const LOCALE_KEY = "local";
export const BRAND_KEY = "brand";

export const DEFAULT_BRAND = "kaztour";
export const RU_LOCALE = "ru-RU";
