import { Box } from "@twilio-paste/box";
import { Button } from "@twilio-paste/core";
import { FC } from "react";
import { ArrowBackIcon } from "@twilio-paste/icons/esm/ArrowBackIcon";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store";

export const ConvosDrawerButton: FC = () => {
  const dispatch = useDispatch();

  const { setConvoDrawerState } = bindActionCreators(actionCreators, dispatch);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="start"
    >
      <Button variant="link" onClick={() => setConvoDrawerState(true)}>
        <ArrowBackIcon decorative={true} title="Open Menu" size="sizeIcon50" />
      </Button>
    </Box>
  );
};
