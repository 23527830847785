import {
  BRAND_KEY,
  CRM_TOKEN_KEY,
  EMAIL_KEY,
  LOCALE_KEY,
  TWILIO_TOKEN_KEY,
} from "./constants";

const setTwilioToken = (token: string) => {
  localStorage.setItem(TWILIO_TOKEN_KEY, token);
};

const setCRMToken = (token: string) => {
  localStorage.setItem(CRM_TOKEN_KEY, token);
};

const setEmail = (email: string) => {
  localStorage.setItem(EMAIL_KEY, email);
};

const getCrmToken = () =>
  localStorage.getItem(CRM_TOKEN_KEY)
    ? `Bearer ${localStorage.getItem(CRM_TOKEN_KEY)}`
    : null;

const removeAllTokens = () => {
  localStorage.removeItem(CRM_TOKEN_KEY);
  localStorage.removeItem(TWILIO_TOKEN_KEY);
  localStorage.removeItem(LOCALE_KEY);
  localStorage.removeItem(EMAIL_KEY);
  localStorage.removeItem(BRAND_KEY);
};

const getUserEmail = () => localStorage.getItem(EMAIL_KEY);
const getLocale = () => localStorage.getItem(LOCALE_KEY);
const setLocale = (locale: string) => localStorage.setItem(LOCALE_KEY, locale);

const setDefaultBrand = (brand: string) =>
  localStorage.setItem(BRAND_KEY, brand);
const getDefaultBrand = () => localStorage.getItem(BRAND_KEY);

export const LSService = {
  setTwilioToken,
  setCRMToken,
  setEmail,
  getCrmToken,
  getUserEmail,
  removeAllTokens,
  getLocale,
  setLocale,
  setDefaultBrand,
  getDefaultBrand,
};
